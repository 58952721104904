@import url(../fonts/fonts.css);
/*
* COLORS
*/
/* rudimentary dark theme */
/*
* TYPOGRAPHY
*/
/*
* NUMBERS
*/
html {
  box-sizing: border-box;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, height 0.2s, width 0.2s, transform 0.2s; }

*, *:before, *:after {
  box-sizing: inherit;
  transition: inherit; }

html {
  font-family: "Stint Ultra Expanded", serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  color: #222222; }
  @media screen and (max-width: 1244px) {
    html {
      font-size: 14.4px; } }

h1 {
  font-size: 3rem; }

h2 {
  font-size: 2.5rem; }

h3 {
  font-size: 1.8rem; }

h4 {
  font-size: 1.2rem; }

h5 {
  font-size: 0.8rem; }

h6 {
  font-size: 0.5rem; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display SC", sans-serif;
  line-height: 1; }

a {
  color: #FF4400;
  text-decoration: none; }
  a p {
    color: #222222; }

.cta {
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  font-weight: bolder;
  border: 3px solid #3914AF; }
  .cta:hover, .cta:focus {
    filter: unset;
    border-color: #FF4400; }
  .cta.cta-primary {
    background-color: #3914AF;
    color: #FFFFFF; }
    .cta.cta-primary:hover, .cta.cta-primary:focus {
      background-color: #FF4400; }
  .cta.cta-secondary {
    color: #3914AF; }
    .cta.cta-secondary:hover, .cta.cta-secondary:focus {
      color: #FF4400; }

.hidden {
  display: none; }

.hidden {
  display: none; }

body {
  display: grid;
  grid-template-columns: auto minmax(200px, 25%);
  grid-template-rows: repeat(3, auto);
  margin: 0; }
  @media screen and (max-width: 1000px) {
    body {
      grid-template-columns: 100%;
      grid-template-rows: repeat(4, auto); } }
  body header.site-header {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    height: 80px;
    z-index: 2;
    position: sticky;
    top: 0; }
    body header.site-header * {
      max-height: 100% !important; }
  body section#sidebar {
    grid-column: 2 / 3;
    grid-row: 1 / 4;
    width: calc(100% - 3px); }
    @media screen and (max-width: 1000px) {
      body section#sidebar {
        grid-column: 1 / 2;
        grid-row: 3 / 4; } }
  body section#main {
    grid-column: 1 / 2;
    grid-row: 2 / 3; }
  body footer {
    grid-column: 1 / 2;
    grid-row: 3 / 4; }
    @media screen and (max-width: 1000px) {
      body footer {
        grid-row: 4 / 5; } }

header.site-header {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Playfair Display SC", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.2rem;
  background-color: #FFFFFF;
  box-shadow: -10px 0px 10px 0px #FF4400; }
  header.site-header:before {
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    margin-right: auto;
    background-color: #FF4400;
    clip-path: polygon(0% 100%, 100% 100%, calc(100% - (80px * 0.75)) 0%, 0% 0%); }
  header.site-header a.site-title {
    height: 100%;
    max-width: 40%;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
    padding-left: 2rem;
    font-family: "Playfair Display SC", sans-serif;
    font-size: 1.5rem;
    font-weight: bolder;
    color: #FFFFFF; }
    header.site-header a.site-title:hover, header.site-header a.site-title:focus {
      color: #3914AF; }
  header.site-header nav.site-header-nav {
    width: 100%;
    height: 100%; }
    header.site-header nav.site-header-nav .menu-toggle-mobile {
      display: none; }
    header.site-header nav.site-header-nav ol.menu {
      height: 100%;
      max-width: calc(100% - (80px * 0.75));
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      padding: 0; }
      header.site-header nav.site-header-nav ol.menu li {
        padding: 0 1vw;
        z-index: 1; }
        header.site-header nav.site-header-nav ol.menu li a {
          color: #FFFFFF;
          font-weight: normal; }
          header.site-header nav.site-header-nav ol.menu li a:hover, header.site-header nav.site-header-nav ol.menu li a:focus {
            color: #3914AF; }
        header.site-header nav.site-header-nav ol.menu li i {
          color: inherit;
          font-weight: inherit;
          color: inherit;
          font-size: 1.3em; }
    @media screen and (max-width: 767px) {
      header.site-header nav.site-header-nav {
        width: unset;
        height: unset;
        position: fixed;
        flex-direction: column;
        z-index: 4;
        color: #FFFFFF;
        padding-top: 5rem; }
        header.site-header nav.site-header-nav input#menu-toggle {
          display: block;
          border: none;
          padding: 0;
          white-space: nowrap;
          height: 1px;
          width: 1px;
          opacity: 0; }
        header.site-header nav.site-header-nav input#menu-toggle ~ label {
          --hamburger-size: 35px;
          position: fixed;
          height: var(--hamburger-size);
          width: var(--hamburger-size);
          margin: 1em auto;
          margin-top: 0;
          right: 1rem;
          top: calc(80px / 2); }
          header.site-header nav.site-header-nav input#menu-toggle ~ label, header.site-header nav.site-header-nav input#menu-toggle ~ label:before, header.site-header nav.site-header-nav input#menu-toggle ~ label:after {
            cursor: pointer;
            border-radius: 3px;
            height: 5px;
            width: var(--hamburger-size);
            background-color: #3914AF;
            display: block;
            content: ''; }
          header.site-header nav.site-header-nav input#menu-toggle ~ label:before, header.site-header nav.site-header-nav input#menu-toggle ~ label:after {
            position: absolute; }
          header.site-header nav.site-header-nav input#menu-toggle ~ label::before {
            top: -10px; }
          header.site-header nav.site-header-nav input#menu-toggle ~ label::after {
            bottom: -10px; }
        header.site-header nav.site-header-nav input#menu-toggle:checked ~ label {
          background-color: unset; }
          header.site-header nav.site-header-nav input#menu-toggle:checked ~ label::before {
            transform: rotate(45deg);
            top: 0; }
          header.site-header nav.site-header-nav input#menu-toggle:checked ~ label::after {
            transform: rotate(-45deg);
            bottom: 0; }
        header.site-header nav.site-header-nav input#menu-toggle:checked ~ ol.menu {
          height: calc(100vh - 80px);
          width: 100vw; }
        header.site-header nav.site-header-nav:after {
          content: '';
          display: block;
          position: fixed;
          width: 100%;
          height: calc(100vh - 80px);
          left: 0;
          top: 80px;
          z-index: -1;
          background-color: #3914AF;
          transition: clip-path 0.2s;
          clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%); }
        header.site-header nav.site-header-nav ol.menu {
          background-color: #FF4400;
          position: fixed;
          overflow: hidden;
          flex-direction: column;
          align-items: start;
          top: 80px;
          left: 0px;
          height: 0;
          width: 50vw;
          max-width: unset; }
          header.site-header nav.site-header-nav ol.menu li {
            padding: 1rem;
            font-size: 1.75em; }
            header.site-header nav.site-header-nav ol.menu li a {
              margin: 2rem; } }

section#sidebar {
  border-left: 3px solid #FF4400;
  background-color: #ffece6;
  position: sticky;
  top: 0;
  overflow: scroll;
  height: 100vh;
  width: 100%; }
  @media screen and (max-width: 1000px) {
    section#sidebar {
      display: none; } }

section#posts .post-card {
  height: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media screen and (max-width: 767px) {
    section#posts .post-card {
      border-top: 3px solid #FF4400; } }
  section#posts .post-card .inner {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFFFFF;
    clip-path: polygon(0% 100%, 50% 100%, calc(50% + ((400px / 2) * 0.75)) 50%, 50% 0%, 0% 0%);
    filter: opacity(0.85); }
    @media screen and (max-width: 767px) {
      section#posts .post-card .inner {
        clip-path: polygon(0% 30%, 0% 100%, 100% 100%, 100% 30%, 50% 20%); } }
    section#posts .post-card .inner, section#posts .post-card .inner > *, section#posts .post-card .inner h1 {
      transition: filter 0.2s; }
    section#posts .post-card .inner .content {
      padding: 2rem;
      word-break: break-word; }
      @media screen and (max-width: 767px) {
        section#posts .post-card .inner .content {
          height: 70%;
          position: absolute;
          bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: center; }
          section#posts .post-card .inner .content * {
            margin: 0; }
          section#posts .post-card .inner .content h2 {
            margin-bottom: 1vh; } }
      @media screen and (min-width: calc(767px - 1px)) {
        section#posts .post-card .inner .content {
          width: 50%; } }
    section#posts .post-card .inner:hover, section#posts .post-card .inner:focus {
      filter: opacity(1); }
      section#posts .post-card .inner:hover h1, section#posts .post-card .inner:focus h1 {
        color: #3914AF;
        filter: brightness(1); }
  @media screen and (min-width: calc(767px - 1px)) {
    section#posts .post-card:nth-child(even) .inner {
      clip-path: polygon(100% 100%, 50% 100%, calc(50% - ((400px / 2) * 0.75)) 50%, 50% 0%, 100% 0%); }
      section#posts .post-card:nth-child(even) .inner .content {
        margin-left: 50%; } }

#single-post div.splash-image {
  width: 100%;
  height: auto;
  max-height: 50vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  #single-post div.splash-image img {
    width: 100%;
    height: auto; }

#single-post div.content {
  width: 45em;
  max-width: 90%;
  margin: 0 auto; }
  #single-post div.content img {
    display: block;
    max-width: 90%;
    margin: 1em auto; }

fieldset.toggle {
  border: none;
  padding: 0;
  white-space: nowrap; }
  fieldset.toggle legend {
    font-size: 2px;
    position: absolute;
    opacity: 0; }
  fieldset.toggle input {
    opacity: 0; }
  fieldset.toggle input ~ label {
    display: inline-block;
    position: relative;
    z-index: 2;
    margin-left: 3rem; }
    fieldset.toggle input ~ label::before, fieldset.toggle input ~ label::after {
      content: "";
      height: 1rem;
      position: absolute;
      left: 0;
      top: calc((100% - (2 * 1rem)) / 2);
      margin-left: -3rem;
      transition: filter 0.2s; }
    fieldset.toggle input ~ label::before {
      width: 2.5rem;
      border-radius: 0.5rem;
      background-color: #aaa; }
    fieldset.toggle input ~ label::after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f186";
      color: #222222;
      margin-left: calc(-3rem - 0.1em);
      vertical-align: middle;
      display: inline-block;
      line-height: 1rem;
      font-size: 1.5rem;
      transition: margin-left 0.2s; }
  fieldset.toggle input:checked ~ label::after {
    margin-left: calc(-1.5rem + 0.1em);
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f185";
    color: #FFFFFF; }
  fieldset.toggle input:checked ~ label::before {
    filter: invert(1); }

.dark-theme header.site-header:before {
  background-color: #3914AF; }

.dark-theme header.site-header .inner #menus {
  background-color: #222222; }
  .dark-theme header.site-header .inner #menus #menu-nav-ctas li:first-child > a {
    color: #FF4400;
    background-color: #222222;
    border-color: #FF4400; }
    .dark-theme header.site-header .inner #menus #menu-nav-ctas li:first-child > a:hover {
      color: #3914AF;
      background-color: #222222;
      border-color: #3914AF; }
  .dark-theme header.site-header .inner #menus #menu-nav-ctas li:last-child > a {
    color: #222222;
    background-color: #FF4400;
    border-color: #FF4400; }
    .dark-theme header.site-header .inner #menus #menu-nav-ctas li:last-child > a:hover {
      color: #222222;
      background-color: #3914AF;
      border-color: #3914AF; }
  .dark-theme header.site-header .inner #menus .menu-social-media-container ul li a {
    color: #FF4400; }
    .dark-theme header.site-header .inner #menus .menu-social-media-container ul li a:hover {
      color: #3914AF; }

.dark-theme main#main .post-card a {
  background-color: #222222; }
  .dark-theme main#main .post-card a p {
    color: #FFFFFF; }
  .dark-theme main#main .post-card a:hover h2 {
    color: #FFFFFF; }

.dark-theme section#sidebar {
  border-color: #3914AF; }
  .dark-theme section#sidebar section {
    background-color: #c2b2f6; }
    .dark-theme section#sidebar section#sidebar-upper .sidebar-card {
      border-color: #3914AF; }
      .dark-theme section#sidebar section#sidebar-upper .sidebar-card a {
        background-color: #222222; }
